import { type Ref, ref } from "vue";

export interface ToastMessage {
  header: string;
  text?: string;
  type: "success" | "danger" | "warning";
  noAutoHide?: boolean;
}

const messages: Ref<ToastMessage[]> = ref([]);

export function useToasts(): {
  messages: Ref<ToastMessage[]>;
  add: (message: ToastMessage) => void;
} {
  function add(message: ToastMessage): void {
    messages.value.push(message);
  }

  return { messages, add };
}
