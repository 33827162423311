import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "@/router";
import { usePlugins } from "@/plugins";
import i18n from "@/i18n";

const app = createApp(App);

app.use(i18n);
app.use(createPinia());
app.use(router);

usePlugins(app);

app.mount("#app");
