<template>
  <div
    class="full-page-loader backdrop position-absolute vw-100 vh-100 top-0 start-0"
  >
    <div
      class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <b-spinner class="large-spinner"></b-spinner>
      <h5 v-if="text" class="mt-4">{{ text }}</h5>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from "vue";

const props = defineProps<{
  text?: string;
}>();
const { text } = toRefs(props);
</script>

<style scoped lang="scss">
.full-page-loader {
  z-index: $zindex-modal-backdrop;
}
</style>
