import { Language } from "@/models/Constants/Language";
import { createI18n } from "vue-i18n";

export const defaultLocale: string = "sv";
export const defaultLanguage: Language = Language.English;

import en from "./locales/en.json";
import sv from "./locales/sv.json";

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  messages: {
    en,
    sv,
  },
});

export default i18n;
