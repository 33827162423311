<template>
  <Modal
    ref="modal"
    :title="t('components.loggedOutModal.header')"
    :okText="t('components.loggedOutModal.buttonText')"
    :okOnly="true"
    :notCloseable="true"
    @ok="onOk"
  >
    {{ t("components.loggedOutModal.message") }}
  </Modal>
</template>

<script setup lang="ts">
import { Config } from "@/utils/Config";
import { type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import Modal, { type ModalExpose } from "./common/Modal.vue";

const { t } = useI18n();
const modal: Ref<ModalExpose | null> = ref(null);

function onOk(): void {
  hide();

  window.location.href = Config.getLoginRoute();
}

function show(): void {
  if (modal.value !== null) {
    modal.value.show();
  }
}

function hide(): void {
  if (modal.value !== null) {
    modal.value.hide();
  }
}

const exposed: ModalExpose = {
  show,
  hide,
};
defineExpose(exposed);
</script>
