import { type LoginRequest } from "@/models/Authorization/LoginRequest";
import { BaseServiceDefinition } from "./BaseService";

class AuthorizationService extends BaseServiceDefinition {
  constructor() {
    super();
  }

  public async login(loginRequest: LoginRequest): Promise<void> {
    return this.post("/api/v1/authorization/login", loginRequest)
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public async logout(): Promise<void> {
    return this.post("/api/v1/authorization/logout")
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const authorizationService: AuthorizationService = new AuthorizationService();

export default authorizationService;
