import { type LoginRequest } from "@/models/Authorization/LoginRequest";
import authorizationService from "@/services/AuthorizationService";
import { Consoler } from "@/utils/Consoler";
import { defineStore } from "pinia";
import { reactive, toRefs } from "vue";

interface State {
  loggedIn: boolean | undefined;
}

export const useAuthStore = defineStore("auth", () => {
  const state: State = reactive({ loggedIn: undefined });

  const actions = {
    async isLoggedIn(): Promise<void> {
      state.loggedIn = true;
    },

    async isLoggedOut(): Promise<void> {
      state.loggedIn = false;
    },

    async login(loginRequest: LoginRequest): Promise<void> {
      return authorizationService
        .login(loginRequest)
        .then(() => {
          this.isLoggedIn();
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async logout(): Promise<void> {
      return authorizationService
        .logout()
        .then(() => {
          Consoler.debugLog("Logging out succeded");
          this.isLoggedOut();
        })
        .catch((error) => {
          Consoler.debugWarn("Logging out failed", error);
          return Promise.reject(error);
        });
    },
  };

  return {
    ...toRefs(state),
    ...actions,
  };
});
