export enum RouteNames {
  ConfirmUser = "ConfirmUser",
  ConfirmEmail = "ConfirmEmail",
  ConfirmPassword = "ConfirmPassword",
  NotFoundAndError = "NotFoundAndError",
  ProfileRoot = "ProfileRoot",
  Profile = "Profile",
  ProfileScheduling = "ProfileScheduling",
  ProfileSettings = "ProfileSettings",
  Dashboard = "Dashboard",
  SitesRoot = "SitesRoot",
  Sites = "Sites",
  SiteRoot = "SiteRoot",
  SiteDetails = "SiteDetails",
  SiteIncident = "SiteIncident",
  SiteVnc = "SiteVnc",
  SiteReport = "SiteReport",
  SiteLogs = "SiteLogs",
  TeamsRoot = "TeamsRoot",
  Teams = "Teams",
  TeamDetails = "TeamDetails",
  NewsDetails = "NewsDetails",
  PrivacyPolicy = "PrivacyPolicy",
  // TODO: Activate once services pages should be shown
  // ServicesRoot = "ServicesRoot",
  // Services = "Services",
  // ServiceDetails = "ServicesDetails",

  PublicSiteLogs = "PublicSiteLogs",
  SitePerformanceTestingReport = "SitePerformanceTestingReport",
}
