import { type App } from "vue";
import BootstrapVue3 from "bootstrap-vue-3";

// Optional, since every component import their Bootstrap funcionality
// the following line is not necessary
// import "bootstrap";

export function useBootstrapVue3(app: App): void {
  app.use(BootstrapVue3);
}
