import { type LocaleObject } from "yup";

export const sv: LocaleObject = {
  mixed: {
    default: "${path} är ogiltig",
    required: "${path} är ett obligatoriskt fält",
    defined: "${path} måste vara definierat",
    notNull: "${path} får inte vara null",
  },
  string: {
    length: "${path} måste innehålla exakt ${length} tecken",
    min: "${path} måste innehålla minst ${min} tecken",
    max: "${path} får inte innehålla fler än ${max} tecken",
    matches: '${path} måste matcha följande: "${regex}"',
    email: "${path} måste vara en giltig e-post",
    url: "${path} måste vara en giltig URL",
    uuid: "${path} måste vara en giltig UUID",
    trim: "${path} måste vara en trimmad sträng",
    lowercase: "${path} får enbart innehålla små bokstäver",
    uppercase: "${path} får enbart innehålla stora bokstäver",
  },
  number: {
    min: "${path} måste vara större än eller lika med ${min}",
    max: "${path} måste vara mindre än eller lika med ${max}",
    lessThan: "${path} måste vara mindre än ${less}",
    moreThan: "${path} måste vara större än ${more}",
    positive: "${path} måste vara ett positivt nummer",
    negative: "${path} måste vara ett negativt nummer",
    integer: "${path} måste vara ett heltal",
  },
  date: {
    min: "${path} måste vara senare än ${min}",
    max: "${path} måste vara tidigare än ${max}",
  },
  boolean: {
    isValue: "${path} måste vara ${value}",
  },
  array: {
    min: "${path} måste innehålla minst ${min} objekt",
    max: "${path} får inte innehålla fler än ${max} objekt",
    length: "${path} måste innehålla ${length} objekt",
  },
};
