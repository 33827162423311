<template>
  <div
    class="modal-buttons d-flex justify-content-end mt-4"
    :class="{ 'flex-wrap': flexWrapButtons }"
  >
    <slot />
    <b-button
      v-if="!okOnly"
      class="cancel-button me-2"
      variant="outline-dark"
      data-bs-dismiss="modal"
      :disabled="disabled"
      @click="onCancel"
    >
      {{ t("components.common.modalButtons.cancel") }}
    </b-button>
    <b-button
      :type="okIsSubmit ? 'submit' : 'button'"
      class="ok-button"
      :variant="okVariant ? okVariant : 'primary'"
      :disabled="disabled"
      @click="onOk"
    >
      <b-spinner v-if="showSpinner" small class="me-2"></b-spinner>
      <template v-if="okText">{{ okText }}</template>
      <template v-else>{{ t("components.common.modalButtons.ok") }}</template>
    </b-button>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  okOnly?: boolean;
  okText?: string;
  okIsSubmit?: boolean;
  okVariant?: "primary" | "success" | "danger" | "warning";
  disabled?: boolean;
  showSpinner?: boolean;
  flexWrapButtons?: boolean;
}>();
const { okOnly, okText, okIsSubmit, disabled, showSpinner } = toRefs(props);

const emit = defineEmits<{
  (e: "ok"): void;
  (e: "cancel"): void;
}>();

function onOk() {
  if (okIsSubmit?.value !== true) {
    emit("ok");
  }
}

function onCancel() {
  emit("cancel");
}
</script>

<style scoped lang="scss">
.modal-buttons {
  &.flex-wrap {
    gap: map-get($spacers, 3) 0;
  }
}

.ok-button {
  min-width: 148px;
}

.cancel-button {
  padding-left: map-get($spacers, 4_5);
  padding-right: map-get($spacers, 4_5);
}
</style>
