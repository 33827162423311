import { type App } from "vue";
import { useBootstrapVue3 } from "./bootstrap-vue-3";
import { usePopper } from "./vue3-popper";
import { useVueChartJs } from "./vue-chart-js";

export function usePlugins(app: App): void {
  useBootstrapVue3(app);
  usePopper(app);
  useVueChartJs();
}
