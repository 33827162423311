import { Config } from "./Config";

class ConsoleHelperDefinition {
  private get fullConsole(): boolean {
    return Config.FullConsole;
  }

  /* eslint-disable no-console */
  public log(message?: any, ...optionalParams: any[]) {
    if (console && console.log) {
      console.log(message, ...optionalParams);
    }
  }

  public warn(message?: any, ...optionalParams: any[]) {
    if (console && console.warn) {
      console.warn(message, ...optionalParams);
    }
  }

  public debugLog(message?: any, ...optionalParams: any[]) {
    if (this.fullConsole && console && console.log) {
      console.log(message, ...optionalParams);
    }
  }

  public debugWarn(message?: any, ...optionalParams: any[]) {
    if (this.fullConsole && console && console.warn) {
      console.warn(message, ...optionalParams);
    }
  }

  public debugGroup(name?: string, ...optionalParams: any[]) {
    if (this.fullConsole && console && console.group) {
      console.group(name, ...optionalParams);
    }
  }

  public debugGroupEnd() {
    if (this.fullConsole && console && console.groupEnd) {
      console.groupEnd();
    }
  }
  /* eslint-enable no-console */
}

export const Consoler = new ConsoleHelperDefinition();
