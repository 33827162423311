<template>
  <b-modal
    v-model="showModal"
    :hideFooter="true"
    :centered="verticallyInTop !== true"
    :no-close-on-esc="notCloseable === true"
    :no-close-on-backdrop="notCloseable === true"
    :hide-header-close="notCloseable === true"
    :size="size"
    @hide="onHide"
    @ok="onOk"
    @cancel="onCancel"
  >
    <template #title>
      <div class="d-flex align-items-center">
        <img v-if="titleIcon" :src="titleIcon" alt="" class="me-2 pe-1" />
        <div>
          <span>{{ title }}</span>
          <span v-if="titleSuffix" class="fs-6 fw-normal">{{
            titleSuffix
          }}</span>
        </div>
      </div>
    </template>

    <slot />

    <ModalButtons
      v-if="!noButtons"
      :okOnly="okOnly"
      :okText="okText"
      :okVariant="okVariant"
      :disabled="buttonsDisabled"
      @ok="onOk"
      @cancel="onCancel"
    />
  </b-modal>
</template>

<script setup lang="ts">
import { ref, toRefs } from "vue";
import ModalButtons from "./ModalButtons.vue";

export interface ModalExpose {
  show(): void;
  hide(): void;
}

const props = defineProps<{
  title?: string;
  titleIcon?: string;
  titleSuffix?: string;
  okOnly?: boolean;
  okText?: string;
  okVariant?: "primary" | "success" | "danger" | "warning";
  buttonsDisabled?: boolean;
  noButtons?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  verticallyInTop?: boolean;
  notCloseable?: boolean;
  hideOnOk?: boolean;
  hideOnCancel?: boolean;
}>();
const {
  title,
  titleIcon,
  titleSuffix,
  okOnly,
  okText,
  buttonsDisabled,
  noButtons,
  size,
  verticallyInTop,
  notCloseable,
  hideOnOk,
  hideOnCancel,
} = toRefs(props);

const emit = defineEmits<{
  (e: "ok"): void;
  (e: "cancel"): void;
  (e: "hide"): void;
}>();

const exposed: ModalExpose = {
  show,
  hide,
};
defineExpose(exposed);

const showModal = ref(false);

function onOk() {
  emit("ok");
  if (hideOnOk?.value === true) {
    hide();
  }
}

function onCancel() {
  if (hideOnCancel?.value) {
    onHide();
  }

  emit("cancel");
}

function onHide() {
  showModal.value = false;
  emit("hide");
}

function show() {
  showModal.value = true;
}

function hide() {
  showModal.value = false;
}
</script>
