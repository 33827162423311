<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-4 p-lg-5">
    <Toast
      v-for="(message, index) in messages"
      :key="index"
      :message="message"
    />
  </div>
</template>

<script setup lang="ts">
import { useToasts } from "@/utils/toasts";
import Toast from "./common/Toast.vue";

const { messages } = useToasts();
</script>

<style scoped lang="scss">
.toast-container {
  z-index: $zindex-modal + 1;
}
</style>
