import { Language } from "@/models/Constants/Language";
import { Config } from "./Config";
import { DateTime, type Zone } from "luxon";
import humanizeDuration from "humanize-duration";

export const dateFormats = {
  dateFormat: "yyyy-LL-dd",
  dateWithTimeFormat: "yyyy-LL-dd · HH:mm",
  dateWithTimeWithSecondsFormat: "yyyy-LL-dd · HH:mm:ss",
  timeFormat: "HH:mm",
  timeWithSecondsFormat: "HH:mm:ss",
  dayOfWeekFormat: "cccc",
  shortDateFormat: "yy-LL-dd",
  shortDateWithTimeFormat: "yy-LL-dd HH:mm:ss",
  shortCompressedDateFormat: "yyLLdd",
  shortCompressedDateWithTimeFormat: "yyLLdd HH:mm:ss",
};

export function formatDateWithFormat(
  value: string | Date,
  dateFormat: string
): string {
  return DateTime.fromJSDate(new Date(value))
    .setLocale(getLuxonLocale())
    .toFormat(dateFormat);
}

export function formatDateWithFormatWithZone(
  value: string | Date,
  dateFormat: string,
  zone: string | Zone
): string {
  return DateTime.fromJSDate(new Date(value))
    .setZone(zone)
    .setLocale(getLuxonLocale())
    .toFormat(dateFormat);
}

export function formatDate(value: string | Date): string {
  return formatDateWithFormat(value, dateFormats.dateFormat);
}

export function formatDateWithTime(value: string | Date): string {
  return formatDateWithFormat(value, dateFormats.dateWithTimeFormat);
}

export function formatDateWithTimeWithSeconds(value: string | Date): string {
  return formatDateWithFormat(value, dateFormats.dateWithTimeWithSecondsFormat);
}

export function formatTime(value: string): string {
  return formatDateWithFormat(value, dateFormats.timeFormat);
}

export function formatDateDistance(value: string): string {
  return (
    DateTime.fromJSDate(new Date(value)).toRelative({
      locale: getLuxonLocale(),
    }) ?? ""
  );
}

export function formatDuration(milliseconds: number): string {
  return humanizeDuration(milliseconds, {
    units: ["m"],
    language: getHumanizeDurationLanguage(),
    round: true,
  });
}

export function formatDurationHms(milliseconds: number): string {
  return humanizeDuration(milliseconds, {
    units: ["h", "m", "s"],
    language: getHumanizeDurationLanguage(),
    round: true,
  });
}

export function formatDurationDefault(milliseconds: number): string {
  return humanizeDuration(milliseconds, {
    language: getHumanizeDurationLanguage(),
    round: true,
  });
}

export function getMillisecondsBetweenDates(
  startDate: Date,
  endDate: Date
): number {
  return endDate.getTime() - startDate.getTime();
}

export function getMinutesBetweenDates(startDate: Date, endDate: Date): number {
  const diff = endDate.getTime() - startDate.getTime();
  return diff / 60000;
}

export function isSameDay(date1: Date, date2: Date): boolean {
  return DateTime.fromJSDate(date1).hasSame(DateTime.fromJSDate(date2), "day");
}

export function isAfterDay(date1: Date, date2: Date): boolean {
  return DateTime.fromJSDate(date1) > DateTime.fromJSDate(date2);
}

export function getLuxonLocale(): string {
  switch (Config.currentLocale) {
    case Language.Swedish:
      return "sv";
    case Language.English:
    default:
      return "en-GB";
  }
}

function getHumanizeDurationLanguage(): string {
  switch (Config.currentLocale) {
    case Language.Swedish:
      return "sv";
    case Language.English:
    default:
      return "en";
  }
}
