import { type UpdateEmailRequest } from "@/models/Users/UpdateEmailRequest";
import { type UpdatePasswordRequest } from "@/models/Users/UpdatePasswordRequest";
import { type UpdateProfileRequest } from "@/models/Users/UpdateProfileRequest";
import { type UserWithSiteAvailabilities } from "@/models/Users/UserWithSiteAvailabilities";
import usersService from "@/services/UsersService";
import { Config } from "@/utils/Config";
import { Consoler } from "@/utils/Consoler";
import { defineStore } from "pinia";
import { reactive, toRefs } from "vue";
import { CommonStore } from "./common/CommonStore";

export const useUserProfileStore = defineStore("userProfile", () => {
  const common = reactive(
    new CommonStore<UserWithSiteAvailabilities>({ freshMinutes: 10 })
  );

  const actions = {
    async load(force?: boolean): Promise<any> {
      const currentlyLoadingPromise = common.currentlyLoadingPromise();
      if (currentlyLoadingPromise !== null) {
        Consoler.debugLog(
          "Already loading, waiting for existing load to finish",
          "userProfile"
        );
        return currentlyLoadingPromise;
      }

      if (force !== true && common.hasFreshData()) {
        return Promise.resolve();
      }

      Consoler.debugLog("Loading userProfile");
      const loadingPromise = usersService
        .getUserProfile()
        .then((result) => {
          Consoler.debugLog("Loading userProfile succeded", result);
          common.setLoadingComplete({ data: result });

          // Set language/locale by user profile
          Config.setLocale(result.language);
        })
        .catch((error) => {
          Consoler.debugWarn("Loading userProfile failed", error);
          common.setLoadingError();
          return Promise.reject(error);
        });

      common.setLoading({ loadingPromise, doNotClearData: true });
      return loadingPromise;
    },

    async updateProfile(request: UpdateProfileRequest): Promise<void> {
      return usersService.updateProfile(request).then(() => {
        // User profile was edited, start reload of data
        this.load(true);
      });
    },

    async updateEmail(request: UpdateEmailRequest): Promise<void> {
      return usersService.updateEmail(request).then(() => {
        // User profile was edited, start reload of data
        this.load(true);
      });
    },

    async updatePassword(request: UpdatePasswordRequest): Promise<void> {
      return usersService.updatePassword(request);
    },

    async deleteUser(): Promise<void> {
      return usersService.deleteUser().then(() => {
        // Clear the state
        common.clear();
      });
    },
  };

  return {
    ...toRefs(common.item),
    ...actions,
  };
});
