import { AvailabilityStatus } from "@/models/Constants/AvailabilityStatus";
import { Language } from "@/models/Constants/Language";
import { UserIncompleteField } from "@/models/Constants/UserIncompleteField";
import { UserRole } from "@/models/Constants/UserRole";
import { type ConfirmEmailRequest } from "@/models/Users/ConfirmEmailRequest";
import { type ConfirmPasswordRequest } from "@/models/Users/ConfirmPasswordRequest";
import { type ConfirmUserRequest } from "@/models/Users/ConfirmUserRequest";
import { type ForgotPasswordRequest } from "@/models/Users/ForgotPasswordRequest";
import { type ResetMfaRequest } from "@/models/Users/ResetMfaRequest";
import { type SetAvailabilityExceptionPeriodRequest } from "@/models/Users/SetAvailabilityExceptionPeriodRequest";
import { type SetAvailabilityScheduleRequest } from "@/models/Users/SetAvailabilityScheduleRequest";
import { type UpdateEmailRequest } from "@/models/Users/UpdateEmailRequest";
import { type UpdatePasswordRequest } from "@/models/Users/UpdatePasswordRequest";
import { type UpdateProfileRequest } from "@/models/Users/UpdateProfileRequest";
import { type UserAvailabilities } from "@/models/Users/UserAvailabilities";
import { type UserWithSiteAvailabilities } from "@/models/Users/UserWithSiteAvailabilities";
import { BaseServiceDefinition } from "./BaseService";
import type { UserNotificationSettings } from "@/models/Users/UserNotificationSettings";
import type { UpdateNotificationSettingRequest } from "@/models/Users/UpdateNotificationSettingRequest";

export enum ConfirmPasswordError {
  PwnedPassword = "PwnedPassword",
}

export enum ConfirmUserError {
  PwnedPassword = "PwnedPassword",
}

export enum UpdatePasswordError {
  PwnedPassword = "PwnedPassword",
  BadPassword = "BadPassword",
}

class UsersService extends BaseServiceDefinition {
  constructor() {
    super();
  }

  public async getUserProfile(): Promise<UserWithSiteAvailabilities> {
    // Temp mock, TODO remove
    // await this.sleep(500);
    // return this.getMockeduserProfile();

    return this.get("/api/v1/users/current")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public async getUserAvailabilities(): Promise<UserAvailabilities> {
    return this.get("/api/v1/users/current/availabilities")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public async getUserNotificationSettings(): Promise<UserNotificationSettings> {
    return this.get("/api/v1/users/current/notificationSettings")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public async confirmUser(
    userId: number,
    request: ConfirmUserRequest
  ): Promise<void> {
    return this.post(`/api/v1/users/${userId}/confirm`, request).catch(
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public async resendInviteLink(userId: number): Promise<void> {
    return this.post(`/api/v1/users/${userId}/resend-invite-link`).catch(
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public async updateProfile(request: UpdateProfileRequest): Promise<void> {
    return this.put("/api/v1/users/current", request).catch((error) => {
      return Promise.reject(error);
    });
  }

  public async updateEmail(request: UpdateEmailRequest): Promise<void> {
    return this.post("/api/v1/users/current/email", request).catch((error) => {
      return Promise.reject(error);
    });
  }

  public async confirmEmail(
    userId: number,
    request: ConfirmEmailRequest
  ): Promise<void> {
    return this.post(`/api/v1/users/${userId}/confirm/email`, request).catch(
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public async updatePassword(request: UpdatePasswordRequest): Promise<void> {
    return this.post("/api/v1/users/current/password", request).catch(
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public async deleteUser(): Promise<void> {
    return this.delete("/api/v1/users/current").catch((error) => {
      return Promise.reject(error);
    });
  }

  public async resetPassword(request: ForgotPasswordRequest): Promise<void> {
    return this.post("/api/v1/users/forgotpassword", request).catch((error) => {
      return Promise.reject(error);
    });
  }

  public async confirmPassword(
    userId: number,
    request: ConfirmPasswordRequest
  ): Promise<void> {
    return this.post(`/api/v1/users/${userId}/confirm/password`, request).catch(
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public async resetMfaForUser(userId: number): Promise<void> {
    return this.post(`/api/v1/users/${userId}/reset-mfa`).catch((error) => {
      return Promise.reject(error);
    });
  }

  public async resetMfaForCurrentUser(request: ResetMfaRequest): Promise<void> {
    return this.post("/api/v1/users/current/reset-mfa", request).catch(
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public async updateAvailabilitySchedule(
    request: SetAvailabilityScheduleRequest
  ): Promise<void> {
    return this.post(
      "/api/v1/users/current/availabilities/schedule",
      request
    ).catch((error) => {
      return Promise.reject(error);
    });
  }

  public async updateAvailabilityExceptionPeriod(
    request: SetAvailabilityExceptionPeriodRequest
  ): Promise<void> {
    return this.post(
      "/api/v1/users/current/availabilities/exceptionPeriod",
      request
    ).catch((error) => {
      return Promise.reject(error);
    });
  }

  public async updateNotificationSetting(
    request: UpdateNotificationSettingRequest
  ): Promise<void> {
    return this.post(
      "/api/v1/users/current/notificationSettings",
      request
    ).catch((error) => {
      return Promise.reject(error);
    });
  }

  private getMockeduserProfile(): UserWithSiteAvailabilities {
    return {
      userId: 1,
      firstName: "Karl",
      lastName: "Karlsson",
      fullName: "Karl Karlsson",
      email: "karl.karlsson@mail.com",
      phoneNumber: "0123456789",
      pictureUrl: "/assets/test/karl-karlsson-profile.png",
      role: UserRole.ConsiliumEmployee,
      availabilityStatus: AvailabilityStatus.Available,
      availabilities: [],
      language: Language.English,
      incompleteInformation: true,
      incompleteFields: [UserIncompleteField.PhoneNumber],
    };
  }
}

const usersService: UsersService = new UsersService();

export default usersService;
