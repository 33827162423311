import i18n, { defaultLanguage } from "@/i18n";
import { Language } from "@/models/Constants/Language";
import { sv } from "@/validation/locales/sv";
import { en } from "@/validation/locales/en";
import { setLocale } from "yup";
import countries from "i18n-iso-countries";
import countriesSv from "i18n-iso-countries/langs/sv.json";
import countriesEn from "i18n-iso-countries/langs/en.json";
import type { CountryCode } from "@/models/CountryCode";

export interface LocalizedCountry {
  alpha2Code: string;
  name: string;
}

class ConfigDefinition {
  private _currentLocale: Language = defaultLanguage;

  public readonly BaseUrl: string = import.meta.env.BASE_URL ?? "";

  public readonly ApiBaseUrl: string = import.meta.env.VITE_API_BASE_URL ?? "";

  public readonly GeneralContactEmail: string =
    import.meta.env.VITE_GENERAL_CONTACT_EMAIL ?? "";

  public readonly ContactLink: string = import.meta.env.VITE_CONTACT_LINK ?? "";

  public readonly ConsiliumAcadamyUrl: string =
    import.meta.env.VITE_CONSILIUM_ACADAMY_URL ?? "";

  public readonly GoogleMapsSearchTermUrlFormat: string =
    import.meta.env.VITE_GOOGLE_MAPS_SEARCH_TERM_URL_FORMAT ?? "";

  public readonly FullConsole: boolean =
    import.meta.env.VITE_FULL_CONSOLE === "true";

  public get isDevelopment(): boolean {
    return import.meta.env.DEV;
  }

  public get currentLocale(): Language {
    return this._currentLocale;
  }

  public constructor() {
    this.initializeCountriesLocales();
  }

  public getLoginRoute(returnUrl?: string): string {
    if (this.isDevelopment) {
      returnUrl = window.location.origin + (returnUrl ?? "");
    }

    return `${this.ApiBaseUrl}/login${
      returnUrl !== undefined ? `?returnUrl=${returnUrl}` : ""
    }`;
  }

  public getLocalizedCountries(): {
    alpha2Code: string;
    name: string;
  }[] {
    return Object.entries(
      countries.getNames(this.getLocaleFromLanguage(this._currentLocale))
    )
      .map(([key, value]) => ({ alpha2Code: key, name: value }))
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }

  public getLocalizedCountryName(countryCode: CountryCode): string | undefined {
    return countries.getName(
      countryCode.toString().toLowerCase(),
      this.getLocaleFromLanguage(this._currentLocale)
    );
  }

  public setLocale(language: Language): void {
    this.setI18nLocale(language);
    this.setValidationLocale(language);
    this._currentLocale = language;
  }

  private getLocaleFromLanguage(language: Language): "en" | "sv" {
    switch (language) {
      case Language.Swedish:
        return "sv";
      case Language.English:
        return "en";
      default:
        return "en";
    }
  }

  private setI18nLocale(language: Language): void {
    let locale: "sv" | "en" = "en";

    if (language === Language.Swedish) {
      locale = "sv";
    }

    i18n.global.locale.value = locale;
  }

  private setValidationLocale(language: Language): void {
    if (language === Language.Swedish) {
      setLocale(sv);
    } else {
      setLocale(en);
    }
  }

  private initializeCountriesLocales(): void {
    countries.registerLocale(countriesSv);
    countries.registerLocale(countriesEn);
  }
}

export const Config = new ConfigDefinition();
