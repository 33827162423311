<template>
  <b-toast
    :modelValue="true"
    :variant="message.type"
    :title="message.header"
    :autoHide="message.noAutoHide !== true"
    :visible="true"
  >
    {{ message.text }}
  </b-toast>
</template>

<script setup lang="ts">
import { type ToastMessage } from "@/utils/toasts";
import { toRefs } from "vue";

const props = defineProps<{
  message: ToastMessage;
}>();
const { message } = toRefs(props);
</script>
