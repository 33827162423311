<template>
  <TheToasts />

  <FullPageLoader v-if="isLoading" />

  <router-view />

  <LoggedOutModal ref="loggedOutModal" />
</template>

<script setup lang="ts">
import "@/scss/index.scss";
import { EventBus } from "@/utils/EventBus";
import LoggedOutModal from "@/components/LoggedOutModal.vue";
import { onBeforeUnmount, type Ref, ref } from "vue";
import { type ModalExpose } from "./components/common/Modal.vue";
import TheToasts from "./components/TheToasts.vue";
import FullPageLoader from "./components/common/FullPageLoader.vue";

const loggedOutModal: Ref<ModalExpose | null> = ref(null);

const isLoading: Ref<boolean> = ref(false);

EventBus.on("ShowLoggedOutModal", onShowLoggedOutModal);
EventBus.on("RouterBeforeNavigationStarted", onRouterBeforeNavigationStarted);
EventBus.on(
  "RouterBeforeNavigationCompleted",
  onRouterBeforeNavigationCompleted
);

function onShowLoggedOutModal() {
  if (loggedOutModal.value !== null) {
    loggedOutModal.value.show();
  }
}

function onRouterBeforeNavigationStarted() {
  isLoading.value = true;
}

function onRouterBeforeNavigationCompleted() {
  isLoading.value = false;
}

onBeforeUnmount(() => {
  EventBus.off("ShowLoggedOutModal", onShowLoggedOutModal);
  EventBus.off(
    "RouterBeforeNavigationStarted",
    onRouterBeforeNavigationStarted
  );
  EventBus.off(
    "RouterBeforeNavigationCompleted",
    onRouterBeforeNavigationCompleted
  );
});
</script>
