import Axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from "axios";
import { Config } from "@/utils/Config";
import { useAuthStore } from "@/stores/auth";
import { ResponseErrorReason } from "./ResponseErrorReason";
import { EventBus } from "@/utils/EventBus";

export abstract class BaseServiceDefinition {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = Axios.create({
      headers: { Pragma: "no-cache", "Cache-Control": "no-cache" },
      withCredentials: true,
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if ((error as AxiosError).response?.status === 401) {
          const authStore = useAuthStore();
          const wasLoggedIn = authStore.loggedIn;
          authStore.isLoggedOut();

          if (wasLoggedIn === true) {
            // User was logged in prior to this, trigger to show the logged out modal.
            EventBus.emit("ShowLoggedOutModal");
          }

          if (wasLoggedIn !== true) {
            // User was not logged in prior to this, go to the login page.
            window.location.href = Config.getLoginRoute();
          }

          return Promise.reject(ResponseErrorReason.Unauthorized);
        }

        return Promise.reject(error);
      }
    );
  }

  protected get(
    url: string,
    params?: any,
    paramsSerializer?: (params: any) => string
  ): Promise<any> {
    return this.axiosInstance.get(Config.ApiBaseUrl + url, {
      params,
      paramsSerializer,
    } as AxiosRequestConfig);
  }

  protected post(
    url: string,
    data?: any,
    params?: any,
    responseType: "json" | "blob" = "json"
  ): Promise<any> {
    return this.axiosInstance.post(Config.ApiBaseUrl + url, data, {
      params,
      responseType,
    });
  }

  protected patch(url: string, data?: any): Promise<any> {
    return this.axiosInstance.patch(Config.ApiBaseUrl + url, data);
  }

  protected put(url: string, data: any, params?: any): Promise<any> {
    return this.axiosInstance.put(Config.ApiBaseUrl + url, data, { params });
  }

  protected delete(url: string): Promise<any> {
    return this.axiosInstance.delete(Config.ApiBaseUrl + url);
  }

  protected sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export const arrayParamsSerializer = (params: any): string => {
  // This serializes array query params as "id=1&id=2", instead of the axios default "id[]=1&id[]=2" which .Net Core doesn't handle without customization.
  let options = "";
  for (const key in params) {
    if (typeof params[key] !== "object" && params[key]) {
      options += `${key}=${params[key]}&`;
    } else if (
      typeof params[key] === "object" &&
      params[key] &&
      params[key].length
    ) {
      params[key].forEach((el: any) => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
};
